const en_US = {

//components

    "XSQBDZ": "Mostrar todas las direcciones",
//ChatRoom

    "ZBZCBQFH": "Los emoji no son compatibles en este momento",
    "FaSongLianJie": "Enviar enlace",
    "YiChangXiaoXi": "Mensaje excepción",
    "QingShuRu": "Por favor ingrese",
    "FaSong": "Enviar",


//DateSelector

    "DQSJXYKSSJ": "La hora actual es menor que la hora de inicio",
    "DQSJCGJSSJ": "El tiempo actual excede el tiempo final",
    "QuXiao": "Cancelar",
    "NianYueRi": "Fecha",
    "ShiFen": "Hora",
    "QueDing": "Confirmar",
    "Nian": "Año",
    "Yue": "Mes",
    "Ri": "Día",
    "Shi": "Hora",
    "Fen": "Puntos",
    "Miao": "S",

//goods-list-card

    "Qi": "Desde",


//goods-promotion

    "1GeCuXiao": "1 promoción",
    "ChaKanZengPin": "Ver regalos",
    "JHDJSHS_1T": "Tiempo final: {d} día {h} hora {m} minuto {s} segundo",
    "YHGZ_ZPBKD": "Reglas preferenciales: regalos gratis",
    "YHGZ_AZZGM": "Reglas preferenciales: disfrute de descuentos basados ​​en la cantidad de compra final",
    "GuanBi": "Cerrar",


//group-filtrate

    "QuanBu": "Todos",
    "ZWXGSJ": "Sin datos",
    "ZuiDiJia": "Precio más bajo",
    "ZuiGaoJia": "Precio más alto",
    "LeiXing": "Tipo",
    "PinPai": "Marca",
    "BiaoQian": "Etiquetas",
    "JiaGeQuJian": "Rango precios",
    "SPMC_GG_BH_": "Busca productos",
    "ZuHeShaiXuan": "Cribado combinatorio",
    "ChongZhi_CanShu": "Restablecer",


//image-upload

    "ZDKSC1ZTP": "Sube hasta {num} foto",
    "ZYXSCTP": "Solo sube fotos",
    "SCTPDXBYXC": "El tamaño de carga de la imagen no puede exceder los {size}",
    "QBYCFSCTP_": "No suba imágenes repetidamente ni asegúrese de que el nombre de la imagen sea único.",
    "ShangChuanChengGong": "Subido correctamente",
    "ZDKSC1Z": "Sube hasta {num}",


//keyboard

    "CCSLZDXZ": "Excedió el cantidad máximo",
    "QiDingLiang": "MOQ",
    "ShouQi": "Guardar",
    "ShanChu": "Eliminar",


//MultiPicker

    "FanHui": "Volver a",


//order-cart

    "Dai": "Generación",
    "YiQuXiao": "Cancelado",
    "ZengPin": "Sorteo",
    "1Zhong": "{num} Und",
    "1Jian": "1 Und",
    "HeJi": "Total",
    "DaiFu": "Ser pagado",


//order-promotion

    "_DDM1YZ1J_": "[Gratis {presentNum} para pedidos superiores a {targetPrice}]",
    "_DDM1YLJ1Y_": "[{price} de descuento en pedidos superiores a {targetPrice}]",
    "_DDM1YLX1Z_": "[{discount} de descuento en pedidos superiores a {targetPrice}]",
    "DingDanCuXiao": "Promoción de pedidos",


//page-ad

    "DLCKJG": "Inicia sesión ver precios",


//page-floating

    "DianPuZhuYe": "Inicio de tienda",
    "GouWuChe": "Carrito",
    "ZaiXianKeFu": "Servicio online",
    "GeRenZhongXin": "Mis datos",
    "LianXiDianHua": "Teléfono contacto",
    "FanHuiDingBu": "Volver arriba",


//page-null

    "ZBDYML": "Página no encontrada",
    "KNWZCW_HZY": "La URL puede ser incorrecta o la página puede ser eliminada",
    "FanHuiShangJi": "Volver a superior",
    "FanHuiShouYe": "De vuelta a casa",


//product-edit

    "SPQDBNWK": "La lista de productos no puede estar vacía.",
    "WoZhiDaoLe": "Yo se",
    "SFQRSCSP": "¿Estás seguro de que deseas eliminar el producto?",
    "QueRen": "Pagar",
    "ZYXSRSZBQB": "Solo se permiten números y no más de 2 decimales",
    "ShangPinDanJia": "Precio unitario",
    "YSPYJ": "Precio original",
    "QSRZDJG": "Ingrese el precio especificado",
    "ShuLiang": "Cantidad",
    "DanWei": "Unidad",
    "DanJia": "Precio unitario",
    "BeiZhu": "Nota",
    "YiChuShangPin": "Eliminar elemento",
    "XiuGai": "Modificar",


//yunshl

    "TPSLCCYXSZ": "¡El número de imágenes supera el valor permitido!",
    "WJBDDY5M_QC": "El archivo no debe ser mayor de 5 m, seleccione nuevamente",
    "TPSCWC": "Carga de imagen completada",


//utils
//BusinessFilter

    "_DGSLM1JZ1J": "[Gratis {presentNum} cuando la cantidad de pedido {num}]",
    "_M1JLXMJ1Y_": "[Disfruta de S/ {price} por {num} unidades ]",
    "_DHJJZ1Y_": "[El precio del pedido baja a S/ {price}]",
    "_M1JLXMJ1Z_": "[Disfruta de un {discount}% de descuento por cada artículo]",
    "_YDHJD1Z_": "[Disfruta de un {discount}% de descuento por cada {num} artículos]",
    "YuJi": "Esperado",
    "MMYDDR": "MM Mes DD Día",
    "KaiShiFaHuo": "Comience a enviar",
    "_DGSLM1Z1_": "[Gratis {presentNum} para la cantidad de pedido {num}]",
    "_M1LXM1Y_": "[Cada S/ {price} por {num} unidades]",
    "_M1LXM1Z_": "[Disfrute de cada {discount}% de descuento por cada {num} compra]",
    "DaiQueRen": "Por confirmer",
    "DaiFaHuo": "Por enviar",
    "DaiShouHuo": "Por recibir",
    "YiWanCheng": "Completado",
    "DaiFuKuan": "Por pagar",
    "YiFuKuan": "Pagado",
    "YiZuoFei": "Inválido",
    "DaiShenHe": "Por verificar",
    "DaiHeXiao": "Cancelación pendiente",
    "YiHeXiao": "Dado de baja",
    "XianXiaZhiFu": "Depósito o Transferencia",
    "ZhiNengDaoZhangShiBie": "线下汇款(智能到账识别)",
    "WeiXinZhiFu": "WeChat pago",
    "ZFBZF": "Pago Alipay",
    "YuEZhiFu": "Depósito",
    "HuoDaoFuKuan": "Contra reembolso",
    "DaiTuiHuo": "Para ser devuelto",
    "DaiTuiKuan": "Reembolso pendiente",
    "ChuangJianDingDan": "Crear orden",
    "BianJiDingDan": "Editar orden",
    "TiJiaoDingDan": "Hacer Pedido",
    "DingDanShenHe": "Revisión pedido",
    "CaiWuShenHe": "Revisión financiera",
    "BoHui": "Desestimar",
    "TianJiaShouKuan": "Agregar pago",
    "ZaiXianZhiFu": "Pagar Online",
    "MercadoPagoZhiFu": "MercadoPago",
    "ShouKuanShenHe": "Revisión recibo",
    "ShouKuanZuoFei": "Recibo inválido",
    "ChuKu": "Saliente",
    "ChuKuShenHe": "Auditoría saliente",
    "FaHuo": "Envío",
    "TianJiaWuLiu": "Añadir logística",
    "XiuGaiWuLiu": "Modificar logística",
    "ChuKuZuoFei": "Fuera de stock",
    "ShouHuo": "Recibo",
    "DingDanHeZhun": "Aprobación pedido",
    "QuXiaoDingDan": "Cancelar",
    "CJTHD": "Crear devolución",
    "DaYin": "Imprimir",
    "DaoChu": "Exportar",
    "DingDanWanCheng": "Pedido finalización",
    "QiangZhiWanCheng": "Terminación forzada",
    "DingDanZuoFei": "Orden nula",
    "MaiZeng": "Comprar regalo",
    "ZhiJiang": "Reducir precio",
    "DaZhe": "Descuento",
    "YuShou": "Preventa",
    "DingDanManZeng": "Regalo",
    "DingDanManJian": "Reducción de orden completa",
    "DingDanManZhe": "Orden completa",
    "XinRenQuan": "Cupón de recién llegado",
    "ManJianQuan": "Cupón completo",
    "ShangPinQuan": "Certificado de mercancia",
    "DaiJieSuan": "Acuerdo pendiente",
    "YiJieSuan": "Resuelto",
    "QuanBuShangPin": "Todos",
    "TuiJianShangPin": "Productos recomendados",
    "XinPin": "Nuevo",
    "ReXiaoShangPin": "Productos Hot",
    "ChangYongShangPin": "Bienes comunes",


//BusinessUtil

    "DaiShouKuan": "Cuentas por cobrar",
    "BuFenShouKuan": "Colección parcial",
    "YiShouKuan": "Recibido",
    "BXYFP": "Sin factura",
    "ZZSPTFP": "Boleta",
    "ZZSZYFP": "Factura con IVA",
    "QiTaFaPiao": "Otras facturas",
    "XianXia": "Offline",
    "WeiXin": "WeChat",
    "ZhiFuBao": "Alipay",
    "YuCunKuan": "Saldo a favor",
    "XianXiaZhuangZhang": "Transferencia offline",
    "XianShangZhiFu": "Pago online",
    "YCKZF": "Saldo a favor",
    "DaiTiJiao": "Por pagar",
    "KuaiDi": "Expreso",
    "SongHuoShangMen": "Contraentrega",
    "ShangMenZiQu": "Recojo en Tiendas",
    "WuLiuHuoYun": "Logística",
    "QiTa": "Otros",


//check-util

    "QSRBXY6WDM": "Ingrese una contraseña de al menos 6 dígitos",
    "QSRBDY20WDM": "Ingrese una contraseña que no tenga más de 20 dígitos",
    "MMGSCW": "Formato de contraseña incorrecta",


//common-utils

    "SJCW_QLXKF": "Error de datos, póngase en contacto con el servicio al cliente",
    "ZBZCQD": "Iniciar sesión no es compatible en este momento",
    "HuJiao": "Llamar",
    "DLZHBCZ": "La cuenta de inicio de sesión no existe",
    "QLXGLYKT": "Póngase en contacto con el administrador para activar",
    "GQYZHBJY": "La cuenta corporativa está deshabilitada",
    "QLXYSDH": "Por favor contacte a Yunshang para ordenar",
    "GQYZHSYQJS": "El período de prueba de la cuenta empresarial finaliza",
    "GQYZHYGQL": "La cuenta corporativa ha expirado",
    "XYXFCNSY": "Necesita renovación para usar",
    "GQYBCZCZH": "Esta cuenta no existe para esta empresa.",
    "NDDHZHYBQY": "Enterprise 1 ha deshabilitado su cuenta de pedido y no puede iniciar sesión. Por favor contacte al administrador",
    "NYBSQY1DHZ": "Ya no eres socio de Enterprise 1 y no puedes iniciar sesión. Por favor contacte al administrador",
    "NDDPYBTY_B": "Su tienda ha sido deshabilitada y no se puede iniciar sesión. Por favor contacte al administrador",
    "GZHWKTDSZB": "Esta cuenta no tiene la característica especial de iluminación. Por favor contacte al administrador",
    "WXSQYZYGQ": "La verificación de autorización de WeChat ha expirado",
    "WXSQSB": "La autorización de WeChat falló",
    "WXSQSB_CWX": "La autorización de WeChat falló con un mensaje de error",
    "DengLuGuoQi": "Caducado",
    "DLGQ_QCXDL": "Inicio de sesión caducado, vuelva a iniciar sesión",


//data-constant

    "ShangJia": "Estantes",
    "YiXiaJia": "Retirado",
    "YiJinYong": "Discapacitados",
    "YiQiYong": "Habilitado",
    "YiTingYong": "Discapacitados",
    "KeHuZhuCe": "Registro clientes",
    "XinZengDaoRu": "Nueva importación",
    "DDDSH": "Revisión de pedido pendiente",
    "DCWSH": "Revisión financiera pendiente",
    "DaiChuKu": "Para ser enviado",
    "DCK_BF_": "Fuera de stock (parcial)",
    "DFH_BF_": "Para ser enviado (parcial)",
    "DSH_BF_": "Para ser recibido (parcial)",
    "DSK_BF_": "Cuentas por cobrar (parciales)",
    "WTHSQ": "Sin solicitud de devolución",
    "THDSH": "Devoluciones pendientes de revisión",
    "THCLZ": "Procesamiento de devolución",
    "THYWC": "Devolución completada",
    "DYDHD": "Imprimir formulario de pedido",
    "DYFHD": "Imprimir factura",
    "DCDHD": "Orden de exportación",
    "DCFHDWE_P": "Exportar factura a archivo Excel / PDF",
    "TuiDanZuoFei": "Reembolso nulo",
    "DTHSH": "Revisión de devoluciones pendientes",
    "DaiRuKu": "Para ser almacenado",
    "DRK_BF_": "Para ser almacenado (parcial)",
    "DFK_BF_": "Pago pendiente (parcial)",
    "ChuangJianTuiDan": "Crea un reembolso",
    "BianJiTuiDan": "Editar reembolso",
    "TiJiaoTuiDan": "Enviar un reembolso",
    "TuiHuoShenHe": "Auditoría de devolución",
    "RuKu": "Almacén",
    "RuKuShenHe": "Auditoría entrante",
    "RuKuZuoFei": "Invalidación",
    "TianJiaFuKuan": "Agregar pago",
    "FuKuanZuoFei": "Pago nulo",
    "FuKuanShenHe": "Revisión de pago",
    "TuiDanWanCheng": "Reembolso completado",
    "QiTaChuKu": "Otros salientes",
    "XiaoShouChuKu": "Ventas agotadas",
    "PanKuiChuKu": "Fuera de stock",
    "DiaoBoChuKu": "Transferir fuera",
    "CaiGouTuiHuo": "Compra devuelve",
    "TuiHuoRuKu": "Devoluciones y almacenaje",
    "CaiGouRuKu": "Compra de almacenamiento",
    "QiTaRuKu": "Otro almacenamiento",
    "PanYingRuKu": "Excedente de stock",
    "DiaoBoRuKu": "Traslado al almacén.",
    "CJCGD": "Crear orden de compra",
    "BJCGD": "Editar orden de compra",
    "TJCGD": "Enviar orden de compra",
    "CGDZF": "Orden de compra nula",
    "CGDWC": "Orden de compra completada",
    "CGTHZF": "Devoluciones de compras obsoletas",
    "CGTHWC": "Devoluciones de compra completadas",
    "THDDY": "Impresión de formulario de devolución",
    "THDDC": "Exportar pedido de devolución",
    "CKDDY": "Impresión de pedidos salientes",
    "CKDDC": "Exportación de pedidos salientes",
    "GLDZH": "Cuenta de gestión",
    "DHDZH": "Cuenta de pedido",
    "ShenHeTongGuo": "Aprobado",
    "SHBTG": "Revisión fallida",
    "WuXuFaPiao": "No se requiere factura",
    "DaiKeXiaDan": "Orden en nombre de",
    "ZiZhuXiaDan": "Orden autónoma",
    "PCShangCheng": "Centro comercial PC",
    "APPShangCheng": "APP mall",
    "TVShangCheng": "Centro comercial de televisión",
    "WeiXinShangCheng": "WeChat Mall",
    "ShouJiShangCheng": "Centro comercial móvil",
    "ADDXSMLTC": "Margen bruto a medida",
    "ADDXSSRTC": "Comisión sobre ingresos por ventas",
    "AKHSSYG": "Por empleado",
    "AnZhiDanRen": "Soltero",
    "ATHJEBLKCT": "Deduzca el monto de la comisión en función del monto devuelto",
    "DDYTHBJXTC": "No hay devoluciones para pedidos",
    "DaiDaKuan": "Pago pendiente",
    "WeiKaiShi": "No empezado",
    "JinXingZhong": "En progreso",
    "YiGuoQi": "Caducado",


//Utils.js

    "YouHuo": "En stock",
    "WuHuo": "Fuera de stock",
    "Yi": "Mil millones",
    "QianWan": "Diez millones",
    "BaiWan": "Millones",
    "Wan": "mil diez",
    "Sheng1Tian": "Queda 1 día",
    "Sheng1XiaoShi": "Queda 1 hora",
    "1Miao": "1 segundo",
    "GangGang": "Justo ahora",
    "1FenZhongQian": "Hace 1 minuto",
    "1XiaoShiQian": "Hace 1 hora",
    "1TianQian": "Hace un dia",
    "1GeYueQian": "Hace 1 mes",
    "1NianQian": "Hace 1 año",
    "WeiZhiLeiXing": "Tipo desconocido",


//WechatUtil

    "YYGBCDSP_K": "Hay un buen producto, mira",
    "ZanWeiSheZhi": "No establecido",


//app

    "HQWXSQXX": "Obtenga información de autorización de WeChat",


//flexible

    "JGJYYDMBQ": "La escala se establecerá de acuerdo con las metaetiquetas existentes",


//index

    "NLJZZ": "Loading...",


//cart-bill

    "ShangPinMingXi": "Detalles producto",
    "ShangPinLeiBie": "Categoría producto",
    "QXZFPNR": "Seleccione el contenido factura",
    "QXZFPLX": "Seleccione un tipo factura",
    "QTXFPTT": "Complete el encabezado factura",
    "FaPiaoTaiTou": "Razón social",
    "NSRSBH": "RUC",
    "FaPiaoNeiRong": "Contenido",
    "FaPiaoXinXi": "Información factura",
    "KaiJuFaPiao": "Facturación",
    "FaPiaoLeiXing": "Tipo de factura",
    "Han": "Con",
    "WeiHan": "No incluido",
    "Shui": "IGV",
    "QSRFPTT": "Por favor ingrese razón social",
    "QSRNSRSBH": "Ingrese el RUC",


//cart-list

    "YiManZu_Man": "Cumplir [lleno",
    "ZaiGou": "Recompra",
    "YMZ_DHJJZ": "Ha quedado satisfecho",
    "JianJiangZhi": "Pedazos abajo",
    "YMZ_YDHJD": "Ha cumplido [precio de pedido original alcanzado",
    "JianMeiJian": "Piezas por pieza",
    "SYTCSLW0_WFJRGWC": "El número de paquetes restantes es 0",
    "QingXuanXeShangPin": "Por favor seleccione un producto!",
    "GSPYXJ": "¡Este producto ya no está disponible!",
    "ZJ2TN": "En los últimos 2 días",
    "QDQCXJSP": "¿Está seguro de que desea borrar el producto?",
    "QingChuChengGong": "Borrado éxito",
    "TCSPBKJRGWC_QQXTCSPZYRGW": "Los Combo no se pueden agregar al carrito de compras, cancele los artículos del paquete y luego muévase al carrito de compras.",
    "SPSLBN": "La cantidad producto no puede",
    "TaoCanHan": "El Combo incluye",
    "TaoCan": "Combo",
    "QHDSL": "Verifique la cantidad",
    "SPSLBNDY": "El cantidad de productos no puede ser inferior a",
    "XianDingLiang": "Cantidad máxima",
    "YiRuShouCang": "Mover a favoritos",
    "QuanXuan": "Todos",
    "JianZeng": "Regalo",
    "JianLiXiang_Man": "Disfruta",
    "Yuan_": "Soles",
    "Zhe_": "Doblar",
    "KCBZ_WFJRGWC": "Inventario insuficiente para agregar al carrito",
    "XDJEDYDY": "El monto pedido es mayor o igual que",
    "WYXJHYSXDSP": "¡No se han eliminado ni caducado productos!",
    "DYQDL": "MOQ",
    "ZSPG1J": "{count} Productos {number} unidades",
    "WanCheng": "Completa",
    "BianJi": "Editar",
    "You": "Tener",
    "ZSPBFHYQ_W": "Los productos no cumplen con los requisitos y no están marcados, haga clic para ver",
    "SPSLBNGY": "El cantidad de productos no puede ser superior a",
    "JXDJ_CKXYGBFHYQSP": "Continúe haciendo clic para ver el siguiente producto no conforme",
    "XiaDan": "Enviar",
    "JianJiang": "Pieza caída",
    "QRYCSP": "¿Confirmar eliminación?",
    "QXZXDSP": "Por favor seleccione un pedido",
    "KCBZ_QCXXZ": "Stock insuficiente, ¡elija de nuevo!",
    "KuCun": "Stock",
    "YCKXD": "Soles puede hacer un pedido",
    "QDSCSP": "¿Estás seguro de que quieres recoger mercancías?",
    "ShouCangChengGong": "Éxito de la colección",
    "GYXDL": "Por encima del límite",
    "LiSheng": "salvar",
    "GWCHSKD_QGYGB": "El carrito de compras aún está vacío.",
    "QuGuangGuang": "Ir de compras",
    "QCXJSP": "Remover sin publicar",
    "ZongE": "Total monto",
    "YouHui": "Descuento",


//
    "QXZJHRQ": "Seleccione una fecha entrega",
    "FuJiaFeiYong": "Recargo",
    "ZhongShangPin_Gong": "Productos",
    "PeiSongShiJian": "Tiempo entrega",
    "QXZPSSJ": "Seleccione un tiempo entrega",
    "DingDanJinE": "Monto Pedido",
    "SCFJ_ZD4Z": "Cargue archivos adjuntos, hasta {num} fotos, no más de {size}",
    "KeYongShangPin": "Productos disponibles",
    "ShiYongShuoMing": "Instrucciones de uso",
    "YouXiaoQi": "Fecha de caducidad",
    "Man": "Lleno",
    "BZZBZCTSFH": "Las observaciones no admiten símbolos especiales.",
    "QingXuanZe": "Por favor seleccione",
    "KaiPiaoXuJia": "Factura necesita agregar",
    "PeiSongFangShi": "Método entrega",
    "ShangPinZongJia": "Precio total",
    "YouHuiQuan": "Cupón",
    "ZhangKeXan": "unidades disponible",
    "DDJEWMZ": "No se cumple el monto pedido",
    "WDYHQ": "Mi cupon",
    "KYYHQ": "Cupones disponibles",
    "YuanKeYong": "Soles disponible",
    "ChaKanDingDan": "Ver orden",
    "ShangPinHanShui": "Producto con IGV",
    "SPWHS": "Producto sin IGV",
    "GeDian": "Puntos",
    "QueRenDingDan": "Enviar Pedido",
    "XZSHDZ": "Agregar dirección envío",
    "ShangPinQingDan": "Lista productos",
    "WKYYHQ": "No hay cupones",
    "HuChi": "Excluyentes",
    "DaiJinQuan": "Cupón",
    "YunFei": "Flete",
    "ShuiFei": "IGV",
    "WKYYY": "Motivo no disponible",
    "YHQYGQ": "El cupón expirado",
    "WDYHQSYSJ": "Cupón caducado",
    "ZDSPJEWMZ": "La monto producto especificada no se cumple",
    "BKYYHQ": "Cupón no disponible",


//cart-presale-order

    "QXZPSFS": "Seleccione un método envío",
    "1ZSP_G": "{num} producto, total",
    "YSSP_YJ": "producto de preventa, esperados",
    "JiaoHuoRiQi": "Fecha de entrega",
    "GMJLY": "Mensaje al vendedor",


//scan-to-cart

    "ZhengZaiChuLi...": "Procesando ...",
    "FSCSP": "Producto no mall",
    "ZZCLTXM": "Procesando código de barras ...",
    "SPTJCG": "Producto agregado exitosamente",
    "QSTXM": "Escanee el código barras",
    "SFSCDQSP": "¿Eliminar elemento?",
    "BQ_ZBDXGSP": "Lo sentimos, no se encontraron productos relacionados",
    "XuanZeDanWei": "Seleccionar unidad",
    "JRGWC": "Añadir al carrito",
    "QXXZDJRSP": "Seleccione primero los productos que se agregarán",
    "ZhengZaiChuLi": "Procesamiento",
    "JRGWCCG": "Añadir al carrito éxito",
    "ZanWuShangPin": "Sin productos",


//earn-brokerage-share

    "XTYJBNFZSP": "¡El sistema te ha ayudado a copiar el enlace del producto!",
    "NKYHDDYDYJ": "Puede obtener la comisión correspondiente. Las comisiones están disponibles en \"Mi\"",
    "LiChaKan": "Ver adentro",
    "WeiXinHaoYou": "WeChat amigos",
    "ErWeiMa": "Código QR",
    "CASBEWM": "Mantenga presionado para identificar el código QR",
    "FZSB_QCXFZ": "Copia fallida, por favor copie de nuevo!",
    "TWEWM": "Código QR",
    "BCDXC": "Guardar en álbum",
    "CATPBCDXC": "Mantenga presionada la imagen para guardarla en el álbum",
    "FuZhiLianJie": "Copiar enlace",
    "FXZYJ": "Compartir comisión de ganancia",
    "YJZGKZQYJ": "Comisiones máximas esperadas",


//goods-share

    "FXSPGLSKH": "Comparta productos",
    "FXHJSCNZSD": "Después de compartir, se generará su micro-centro comercial móvil exclusivo. Puede establecer el precio de los productos en el centro comercial, completar su información de contacto y atraer consumidores los consumidores vienen a comprar.",
    "WSCDQJG_ND": "Precio actual de Micro Mall: el precio de su pedido ×",
    "DaiSheZhi": "Para ser establecido",
    "PengYouQuan": "Círculo de amigos",
    "GGFXSZ": "Cambiar la configuración para compartir",


//wechat-login

    "QXSRZH": "Ingrese su número cuenta",
    "MMGSCW_6_20W": "Formato de contraseña incorrecta (6-20 caracteres o números)",
    "BDYYZH": "Vincula una cuenta existente",
    "WXKJDL": "Inicio sesión rápido de WeChat",
    "BangDingZhangHao": "Vincular cuenta",
    "YYZH_QDL": "Ya tiene una cuenta, vaya a iniciar sesión",
    "ZCXZH": "Regístrese una nueva cuenta",
    "HuanYingNi": "Bienvenido",
    "ZH_SJHM": "Cuenta / Número de móvil",
    "MiMa": "Contraseña",
    "QDLHCZ": "Inicie sesión y opere",


//add-to-cart

    "KCBZ_BNTJG": "Inventario insuficiente para agregar más",


//combined-pay-online

    "ZhiFuChengGong": "¡Pago exitoso!",
    "ZFSB_QCXCS": "¡Pago fallido, por favor intente nuevamente!",


//

    "QXXZGG": "Seleccione la especificación",
    "SXGGX": "Las especificaciones seleccionadas necesitan",
    "GMSLXYQDL_": "La cantidad de compra es menor que el MOQ, ¡compre de nuevo!",
    "GMSLDYXDL_": "La cantidad de compra es mayor que la cantidad de orden límite, ¡compre de nuevo!",
    "ShangPinBianHao": "Producto ID",
    "QiDing": "MOQ",
    "SXGGDGSLBK": "La cantidad pedida de las especificaciones seleccionadas no puede exceder",
    "KCBZ_BNTJ": "Inventario insuficiente para agregar",
    "QXXZSP": "Seleccione un producto",
    "SLBNW0": "La cantidad no puede ser 0",
    "QXZGG": "Por favor seleccione una especificación",
    "LiJiYuDing": "Reserve ahora",


//nav

    "ShouYe": "Inicio",
    "ShangPin": "Producto",
    "DingDan": "Pedido",
    "WoDe": "Mis datos",


//pay-online

    "ZhiFuQuXiao": "Cancelación de pago!",


//account-log

    "ShangPinYongJin": "Comisión de productos",
    "YaoQingJiangLi": "Invitación recompensa",
    "TiXian": "Retirarse",
    "TiXianBoHui": "Retiro de retiro",
    "ZhuanWeiYuE": "Girar para equilibrar",
    "Jin7Tian": "Hace 7 días",
    "Jin30Tian": "Hace 30 días",
    "ShouZhiMingXi": "Detalles ingresos y gastos",
    "ZanWuShuJu": "Sin datos",


//client-statistics

    "WoDeKeHu": "Mi cliente",
    "WoDeYaoQing": "Mi invitación",


//distribution-home

    "LeiJiKeHu": "Clientes acumulados",
    "LeiJiDingDan": "Orden acumulativa",
    "YaoQingHaoYou": "Invitar amigos",
    "LeiJiYaoQing": "Invitación acumulativa",
    "LJSY_Y_": "Ingresos acumulados (Soles)",
    "BHDJS": "Liquidación pendiente",
    "J7TSY_Y_": "Ganancias en los últimos 7 días (Soles)",
    "J7TXZKH_R_": "Nuevos clientes en 7 días.",
    "KTXYJ": "Comisión extraíble",
    "XYJYO": "Necesito animar",
    "ChangJianWenTi": "Problema común",
    "Q_ZMYJX_TG": "Q: Cómo \"Promocionar y ganar dinero\"",
    "A_FXDPND_Z_": "A: Comparta el enlace de la página con el ícono de \"ganancia\" en la tienda, atraiga a amigos para que hagan clic y regístrese como amigo (la relación con el cliente está configurada para registrarse enlace) o realizar un pedido (la relación con el cliente está configurada para realizar un enlace de pedido) un cierto método de cálculo cuenta como su ingreso de \"promoción de dinero\".",
    "Q_RHJS_TGZ": "Q: ¿Cómo calcular el beneficio \"Promocionar dinero\"?",
    "A_MGD_Z_TBD": "A: Cada producto con el ícono de \"ganancia\" tiene un cierto porcentaje de comisión. Sin esta marca, significa que el producto no participa en la \"promoción y ganancia\" dinero \". Después de que un amigo paga con éxito a través de su enlace para compartir, la comisión se calculará por separado en función del porcentaje de comisión de cada artículo en el pedido (no la participación en la promoción se convierte en 0), y finalmente se acumula en los ingresos por comisiones del pedido. El envío, los reembolsos y las deducciones completas se deducirán en el cálculo de los ingresos para otras cantidades, prevalecerá el precio final de la transacción.",
    "Q_TGZQ_DSY": "Q: ¿Cuándo se acreditarán las ganancias de \"Promocionar dinero\"?",
    "A_HYTGNDFX": "A: Después de que un amigo paga con éxito a través de su enlace para compartir, puede ver la promoción en el pedido de ventas en el centro de ventas. El estado está pendiente calcular, después de que el cliente confirme que se ha liquidado el recibo de la transacción del pedido, este producto se transferirá automáticamente a su cuenta personal y podrá retirarse en cualquier momento.",
    "Q_LJKH_LJY": "Q: ¿No hay un apodo de avatar en la lista acumulada de clientes e invitaciones acumuladas?",
    "A_WXYQZYGZ": "A: WeChat requiere que el apodo del avatar solo se pueda obtener prestando atención al número del servicio de autenticación. Los clientes que son el número del servicio de autenticación deben prestar atención al número público de la tienda los números de servicio no certificados siguen al número público de \"pedido en la nube\" y hacen clic en cualquier página de detalles del producto en la tienda para obtener el apodo del avatar.",
    "Q_WSMWASSG": "Q: ¿Por qué no comparto los ingresos de la promoción cuando comparto de acuerdo con las reglas anteriores?",
    "A_1_HYKNBW": "A: 1. Es posible que su amigo no haya establecido una relación de cliente con usted (el amigo hizo clic en el enlace de otro vendedor para registrarse o hacer un pedido) 2. Si un amigo abre su enlace para compartir fuera del entorno WeChat (como el navegador predeterminado del teléfono móvil), si el navegador no habilita las cookies,Las relaciones con los clientes no se registrarán, es decir, los pedidos de promoción no se registrarán 3. El pedido de promoción aún no se registra cuando no hay ningún problema arriba, por favor contacte al personal",


//

    "XiaoShouZhongXin": "Centro de ventas",


//generalize-goods

    "SPMC_GG_BH": "Producto / especificación / SKU",
    "YuJiZhuan": "Se espera que gane",


//invitation-home

    "YaoQingKa": "Invitación",
    "YNYQJR_TGY": "Te invitamos a unirte y promover recompensas ganadoras",
    "DJBCDXC": "Haga clic para guardar en el álbum",
    "JYQKFSGHY_": "Enviar tarjetas de invitación a amigos, tiendas gratificantes",


//join-home

    "HuanYingJiaRu": "Bienvenido a ",
    "YQNCW": "Te invito a ser",
    "LiJiJiaRu": "Únete ahora",


//order-statistics

    "YuJiYongJin": "Comisión estimada",
    "YongJin": "Comisión",
    "YuJiJiangLi": "Recompensa esperada",
    "JiangLi": "Recompensa",
    "SPTGDD": "Pedido promoción",
    "YQJLDD": "Pedido recompensa de invitación",


//performance-statistics

    "YeJiTongJi": "Estadísticas resultado",
    "LJKH_R_": "Cliente acumulativo",
    "SPYJ_Y_": "Comisión (Soles)",
    "HanDaiJieSuan": "Liquidación pendiente",
    "XGMCJS": "Términos explicados",
    "KeHu": "Cliente",
    "HFXYBDLKHG": "Un comprador o distribuidor que tiene una relación de cliente con un distribuidor.",
    "YaoQing": "Invitar",
    "FXYCGYQDXJ": "Distribuidores subordinados invitados con éxito por los distribuidores",
    "FXYTJKHGMH": "La comisión que el distribuidor puede obtener después de que el distribuidor recomienda al cliente comprar",
    "XJFXYTGSPH": "Recompensa recibida por los superiores después de la promoción",


//posters-promoting

    "NiCheng": "Apodo",
    "HaiBaoTuiGuang": "Promoción de carteles",
    "JHBFSGHY_T": "Enviar carteles a amigos, tiendas gratificantes",


//settlement-home

    "JieSuanZhongXin": "Centro de liquidación",
    "KTXJE": "Efectivo extraíble",
    "TiXianJiLu": "Registros de retiro",


//withdraw-account

    "KHRXMBNWK": "¡El nombre del titular de la cuenta no puede estar vacío!",
    "KHHBNWK": "¡El banco de la cuenta no puede estar vacío!",
    "YHKHBNWK": "¡El número de la tarjeta bancaria no puede estar vacío!",
    "SFZTPBNWK": "¡La imagen de identificación no puede estar vacía!",
    "YHKTPBNWK": "¡La imagen de la tarjeta bancaria no puede estar vacía!",
    "BaoCunChengGong": "¡Guardado éxito!",
    "SCSFZ": "Subir ID",
    "SCYHK": "Subir tarjeta bancaria",
    "BaoCun": "Guardar",
    "TiXianZhangHu": "Cuenta de retiro",
    "TS_QTXZSXX": "Recordatorio: complete la información real, puede retirar efectivo solo después de pasar la revisión",
    "KHRXM": "Titular de cuenta",
    "KaiHuHang": "Banco",
    "YinhangKaHao": "Número de bancaria",
    "QSRZSXM": "Ingrese su nombre real",
    "QSRKHHMC": "Ingrese el nombre banco",
    "QSRYHKH": "Ingrese su número de bancaria",


//withdraw-deposit

    "QXSZTXZH": "Configure cuenta retiro",
    "QuSheZhi": "Ir a la configuración",
    "JEXY10Y_BKT": "La cantidad es inferior a 10 yuanes, no hay retiro de efectivo!",
    "JECGKTXJE": "¡La cantidad excede la cantidad de efectivo disponible!",
    "CGDRKTXSX_": "Superar el límite de retiro el mismo día, ¡el límite de retiro para un solo día es de 20,000 yuanes!",
    "QueDingJiang": "Ok lo haré",
    "ZhuanDaoYuE": "Ir al equilibrio?",
    "1_JEDY10YSB": "1. No se permite el retiro de efectivo cuando la cantidad es inferior a 10 yuanes;",
    "2_BCTXDZSJ": "2. El tiempo de retiro para este retiro es dentro de los 5 días hábiles posteriores a la iniciación de la solicitud de retiro exitosa.",
    "3_GBSRWHSS": "3. Los ingresos son ingresos con impuestos incluidos, ¡diríjase a la agencia tributaria correspondiente para declarar y pagar el impuesto sobre la renta personal por su cuenta!",
    "4_ZDYEKYYS": "4. El saldo de transferencia se puede utilizar para comprar en el centro comercial;",
    "TXSQCG_QDD": "Solicitud de retiro exitosa, por favor espere revisión",
    "JEDYKTXJE_": "¡La cantidad es mayor que la cantidad de efectivo disponible y no puede transferirse al saldo!",
    "ZZYECG_QDG": "El saldo de la transferencia es exitoso, por favor verifique en el centro personal",
    "BenCiTiXian": "Esta retirada",
    "ZhuangDaoYuE": "Ir al equilibrio",
    "TiXianDao": "Retirarse a",
    "JEW0BKZZYE": "¡El importe es 0 y no se puede transferir al saldo!",
    "DRTXSX2WY": "Límite de retiro de un día de 20,000 Soles",
    "QuanBuTiXian": "Retirar todo",
    "QueRenTiXian": "Confirmar retiro",


//goods-filter-tab

    "ZuiChangGouMai": "Defecto",
    "MoRenPaiXu": "Defecto",
    "ZongHe": "Defecto",
    "FenLei": "Categoría",
    "XiaoLiangYouXian": "Ventas",
    "XiaoLiang": "Ventas",
    "JiaGe": "Precio",
    "ShaiXuan": "Cribado",
    "ZuiJinGouMai": "Precio",
    "CuXiaoYouXian": "Prioridad de promoción",
    "QuanBuFenLei": "Todos",
    "ZhiKanCuXiao": "Solo promociones",
    "XuanZeFenLei": "Seleccione categoría",
    "QXZFL": "Seleccione una categoría",


//goods-list-default

    "ZWXGSP": "No hay productos relacionados.",


//goods-brand-list

    "PinPaiHui": "Intercambio de marca",


//goods-classify-filter

    "TuiJianFenLei": "Clasificación recomendada",
    "ShangPinFenLei": "Categorias",
    "GengDuo": "Más",


//goods-detail

    "DLZHBSDQQY": "La cuenta de inicio sesión no es la cuenta del cliente corporativo actual",
    "GXNHQ": "Felicidades",
    "SZWSCJGH_C": "Compartir después de establecer el precio del micro centro comercial",
    "JHDJSHS_": "Fecha Envío:",
    "DLHKFX": "Compartir después de iniciar sesión",
    "ZZHQXX_QSH": "Obteniendo información, intente nuevamente más tarde",
    "Zhang": "unidades",
    "Tian": "Día",
    "QXZGGZQPD": "Por favor, seleccione las especificaciones y vaya a la lámpara!",
    "Yuan": "Soles",
    "XS_F_M": "Horas / minutos / segundos",


//integral

    "WoDeJiFen": "Mis puntos",
    "DaBaiLe": "Derrotado",
    "JiFen": "Puntos",
    "DeHuiYuan": "Miembros de",
    "ZhuanJiFen": "Gana puntos",
    "JiFenMingXi": "Detalles puntos",
    "HuanLiPin": "Canjear regalos",
    "CKGDHL": "Ver más regalos",
    "ChaDingDan": "Ver pedido",


//integral-convert

    "DHSLBNCG": "La cantidad de canje no puede exceder",
    "DuiHuanShuLiang": "Cantidad de canje",
    "SuoXuJiFen": "Puntos requeridos",


//integral-detail

    "LiPinXiangQing": "Detalles regalo",
    "WoYaoDuiHuan": "Quiero canjear",
    "ShengYu": "Restante",
    "TuWenXiangQing": "Detalles del producto",
    "YiDuiWan": "Terminado",


//integral-filter

    "FenLeiShaiXuan": "Clasificación",
    "JiFenShaiXuan": "Puntos",


//integral-flow

    "JiFenShouRu": "Puntos de ingreso",
    "JiFenZhiChu": "Gasto de puntos",


//integral-logistics

    "DingDanGenZong": "Seguimiento pedidos",
    "WuLiuGongSi": "Empresa logística",
    "WuLiuDanHao": "Número logística",


//integral-order

    "DingDanLieBiao": "Lista de pedidos",
    "YiFaHuo": "Enviado",
    "JiFenDingDan": "Orden de puntos",
    "ChaKanWuLiu": "Ver logística",


//integral-order-create

    "LPSLBZ_QCX": "No hay suficientes regalos",
    "JiFenBuZu": "Puntos insuficientes",
    "ZhiFuXinXi": "Información pago",
    "JiXuGuangGuang": "Sigue paseando",
    "QTXSHXX": "Por favor complete la información del recibo",
    "DuiHuanChengGong": "Canjeado exito",
    "LPDHQR": "Confirmación de regalos",
    "SCCKJ": "Precio referencia",
    "LiJiZhiFu": "Paga ahora",


//integral-order-detail

    "DingDanXiangQing": "Detalles pedido",
    "DingDanZhuangTai": "Estado pedido",
    "DingDanBianHao": "Pedido",
    "DuiHuanShiJian": "Tiempo redención",
    "ShouHuoXinXi": "Recibiendo información",
    "HuaFeiJiFen": "Gastar puntos",


//integral-rule

    "QDZJF": "Inicia sesión para puntos",
    "XDZJF": "Gane puntos haciendo un pedido",
    "DDWCHKHDYD": "Después de completar el pedido, puede obtener el importe del pedido",
    "1_MRQD_JL": "1. Registro diario, recompensa",
    "2_LXQD3T_EW": "2. Inicie sesión durante 3 días consecutivos con recompensas adicionales",
    "3_LXQD7T_EW": "3. Inicie sesión durante 7 días consecutivos con recompensas adicionales",
    "BeiDeJiFen": "Veces los puntos",
    "DLZJF": "Inicia sesión para ganar puntos",
    "MeiRiDengLu": "Inicio sesión diario",
    "SC_JL": "Centro comercial, recompensas",


//integral-rule-detail

    "GuiZe": "Reglas",


//integral-sign

    "QianDaoYouLi": "Inicia sesión con cortesía",
    "QianDaoGuiZe": "Reglas de check-in",
    "YLXQD": "Registrado consecutivamente",
    "QDCG_NYJLX": "Has iniciado sesión correctamente, has registrado continuamente",
    "QDCG_BCHD": "Inicie sesión correctamente, obtenga esta vez",


//

    "QSRBZ": "Ingrese un comentario",
    "DingDanZengPin": "Pedido regalo",
    "GuiGeBianHao": "SKU",
    "GuiGe": "Especs",


//combined-pay

    "CDDWXFK": "No hay pago por este pedido.",
    "YHYEBZ": "Saldo de usuario insuficiente!",
    "ZFJEBNW0": "¡El monto del pago no puede ser 0!",
    "ZFJEBNDYDD": "El monto del pago no puede ser mayor que el monto del pedido",
    "DFKJE": "Monto a pagar",
    "FuKuanFangShi": "Método pago",
    "FuJian": "Adjunto",
    "ZHYFZ": "Cuenta copiada",
    "GHSKZH": "Cambiar cuenta pago",
    "SYXXZFQSCF": "Para el pago fuera de línea, cargue un archivo adjunto de captura de pantalla de pago para la confirmación financiera.",
    "XXZF_BXXZS": "Pago sin conexión, debe elegir una cuenta de pago",
    "DingDanZhiFu": "Pedido de pago",
    "ZFFS_HDFK": "Método de pago: contra reembolso",
    "BCFKJE": "Monto de pago",
    "YuE": "saldo",
    "FuZhiZhangHao": "Copiar cuenta",
    "WSZXCXZFPZ": "La configuración de pago del applet no está configurada",
    "XCXZFZHJY": "Cuenta de pago del applet deshabilitada",
    "SRZFJE": "Ingrese el monto pago",
    "FuKuanZhangHu": "Cuenta pago",
    "QueRenZhiFu": "Confirmar pago",
    "ShaoHouZhiFu": "Pagar más tarde",


//order

    "FKJLYTJ_DD": "Registro de pago agregado, revisión financiera pendiente",
    "JiXuGuang": "Seguir comprando",
    "BenCiFuKuan": "Este pago",
    "FuKuanChengGong": "Pago exitoso",
    "ZhiFuFangShi": "Pago Método",
    "ZhiFuDingDan": "Pedido pago",


//order-batch-pay

    "QXXZSXZFDD": "Por favor seleccione el pedido a pagar",
    "PiLiangFuKuan": "Pago a granel",
    "FuKuan": "Pago",
    "QuFuKuan": "Pagar",


//order-create-result

    "YingFuJinE": "Monto Pedido",
    "XiaDanShiJian": "Tiempo pedido",


//order-deliver

    "SFQRSH": "¿Confirmas recibo?",
    "DDQRSH_": "Recibo de confirmación?",
    "DFHSP": "Producto ser enviados",
    "ShangPinShuLiang": "Cantidad productos",
    "ChuKuShiJian": "Tiempo entrega",
    "FaHuoShiJian": "Tiempo entrega",
    "WuLiuBeiZhu": "Notas logísticas",
    "QueRenShouHuo": "Confirmar recibo",


//

    "RYFK_QJSYG": "Si se paga, comuníquese con el administrador a tiempo",
    "QSRQXYY": "Ingrese un motivo de cancelación",
    "H_DDJZDGB_QJKF": "Después de que el pedido se cierre automáticamente, pague lo antes posible",
    "TuiHuoQingKuang": "Devoluciones",
    "DingDanZongE": "Pedido total",
    "DingDanBeiZhu": "Notas pedido",
    "FaHuoQingKuang": "Situación de entrega",
    "DingDanFuJian": "Adjuntar pedido",
    "ShenQingTuiHuo": "Aplicar devolución",
    "TDSHJL_QRS": "Un total de 1 registros para recibir, ¿confirma el recibo?",
    "DaiXiaDan": "Pedido para cliente",
    "DingDanHao": "Pedido",
    "XiaDanFangShi": "Método pedido",
    "TuiHuoDan": "Pedido devolución",
    "ShangPinZongE": "Monto total",
    "YunFei_ZongZhong": "Flete (peso total",
    "SF_SPWS": "Taxes (bienes no gravados)",
    "TePiJia": "Precio especial",
    "FuKuanQingKuang": "Pago",
    "TiaoJiLu": "Registros",
    "ZaiLaiYiDan": "Uno mas",
    "QDSCGDD_": "¿Eliminar pedido?",
    "YiQueRen": "Confirmado",
    "YiShouHuo": "Recibido",
    "DaiFuJinE": "Monto Por pagar",
    "YiFuJinE": "Monto pagado",
    "ZanWuJiLu": "Sin registro",


//

    "SPMC_DDH": "Producto / número pedido",
    "FuKuanZhuangTai": "Estado pago",
    "FaHuoZhuangTai": "Estado entrega",
    "QiShiShiJian": "Hora de inicio",
    "ZhongZhiShiJian": "Hora final",


//order-list

    "CaoZuoChengGong": "Operación exitosa",
    "ZBKGM": "No disponible para comprar",
    "DQDDSPYXJ": "El producto del pedido actual ha sido eliminado",
    "DQDDBFSPYXJ": "Se han eliminado algunos productos del pedido actual.",
    "JTJSJSP": "Agregar solo listados",
    "DingHuoDan": "Pedidos",


//order-list

    "DingDanRiZhi": "Registro pedidos",


//order-logistics

    "FuZhiChengGong": "Copia exitosa",
    "FuZhiShiBai": "Copia fallida",
    "FuZhi": "Copia",
    "BoDa": "Marcar",
    "WCWLDHXX": "No hay información de logística",


//order-pay

    "ZFCS_QCXXD": "Se agotó el tiempo de pago, reordenar",
    "YQYYCKZF": "Pagos previos al depósito habilitados",
    "BNDYDFKJE": "No puede ser mayor que la cantidad a pagar",
    "ZFJEBNWK": "El monto del pago no puede estar vacío",
    "QXAPFK_FKH": "Una vez realizado el pago adjunte la captura de su operación para la confirmación de su compra",
    "DDTJCG_QJS": "Pedido enviado correctamente, pague a tiempo",
    "QXZFKFS": "Por favor seleccione un método de pago",
    "SYYEZFH_HX": "Después de pagar con el saldo, aún debe pagar",


//

    "FuKuanJiLu": "Registro de pago",
    "ZWFKJL": "Sin registro pago",
    "FuKuanXiangQing": "Detalles pago",
    "FuKuanJinE": "Monto pago",
    "ZhiDan": "Fabricación",
    "ShenHe": "Revisar",
    "ZuoFei": "Nulo",
    "ZuoFeiYuanYin": "Razón nula",
    "GLDHD": "Orden relacionada",
    "HeXiaoJinE": "Monto amortizado",
    "HeXiaoZhuangTai": "Estado de baja",
    "DanJuZhuangTai": "Estado pedido",
    "KeHuMingCheng": "Nombre cliente",
    "ZhangDanHao": "Número pedido",
    "ShouKuanZhangHao": "Cuenta de cobro",
    "ShouKuanShiJian": "Tiempo de recogida",
    "FuKuanPingZheng": "Voucher de pago",


//order-statistics

    "DingDanTongJi": "Estadísticas pedidos",
    "DingHuo": "Pedido",
    "Bi": "unidades",


//order-statistics-detail

    "DDTJMX": "Por pedidos",
    "AGGTJ": "Por especificaciones",
    "AFLTJ": "Por categoría",
    "DDJE_Y": "Monto de pedido / soles",
    "DHSP_Z": "Ordenar producto / Amable",
    "DingHuoDan_Bi": "Pedido compra / unidades",
    "DingHuoShangPin": "Producto",
    "JinE": "Monto",

//refund-list
    "TuiHuo": "Devoluciones",
//refund-apply

    "THDHWBC_QD": "El formulario de devolución no se ha guardado. ¿Estás seguro de que quieres irte?",
    "QSRSQTHJE": "Por favor ingrese la cantidad solicitada para la devolución",
    "QSRTHJE": "Por favor, introduzca el importe de devolución",
    "QSRBZXX": "Por favor ingrese la información del comentario",
    "YingTuiJinE": "Monto a reembolsar",
    "TJTHSQ": "Enviar una solicitud devolución",
    "QXXZDHQD": "Seleccione primero la lista de pedidos",
    "DingHuoQingDan": "Lista de pedidos",
    "SQTHJE": "Solicitar un monto de devolución",
    "DJXZSP": "Haga clic para seleccionar productos",
    "TuiHuoJinE": "Monto devolución",
//refund-detail
    "THDDXQ": "Detalles del pedido de devolución",
    "YiTuiHuo": "Devuelto",
    "YiTuiKuan": "Reembolsado",
    "THDZT": "Estado del pedido de devolución",
    "TuiHuoDanHao": "Número de nota de devolución",
    "TuiHuoShiJian": "Hora de regreso",
    "TuiHuoCangKu": "Almacén de devolución",
    "THLXR": "Contacto de devolución",
    "TuiHuoQingDan": "Lista de retorno",
    "TuiKuanZongE": "Reembolso total",
    "TuiKuanQingKuang": "Situación de reembolso",
    "DaiTuiJinE": "Cantidad a reembolsar",
    "YiTuiJinE": "Cantidad reembolsada",
//refund-product
    "SPMC_BH_GG": "Nombre del producto / Número / Número de especificación / Código de barras",
    "GZD1TJG": "1 resultado encontrado",
    "JiaRuQingDan": "Agregar a la lista",
//refund-result
    "ShenQingChengGong": "Solicitud exitosa",
    "THDTJCG": "Pedido de devolución enviado correctamente",
    "ChaKanXiangQing": "Ver detalles",
//goods-package-floor

    "HDWKS_WDZL": "El evento no ha comenzado, vuelve más tarde",
    "HDYJS_XCZD": "El evento ha terminado, ven temprano la próxima vez",
    "ZWSZBT": "Sin título establecido",
    "ChaKanGengDuo": "Ver más",


//goods-promotion-floor

    "HCDZSJC": "Diferencia horaria causada por el almacenamiento en caché",
    "DLCKCXJ": "Inicie sesión para ver precios promocionales",
    "ZWSZCX": "No hay promociones configuradas",
    "JuKaiShi": "Desde el principio",
    "Song": "Regalar",
    "JuJieShu": "Fin de la distancia",
    "YiJieShu": "Terminó",


//search-floor

    "XiaoXi": "Mensaje",
    "SaoMaXiaDan": "Pedido con escanea código",


//home-page-custom

    "WZLCSJ": "Datos de piso desconocidos",


//jump-error

    "QXZYSDHAPP": "Descargue la aplicación de pedidos en la nube",
    "QMTYQYGLD": "Experiencia completa de gestión empresarial.",
    "XZYSDHAPP": "Descargue la aplicación de pedidos en la nube",
    "HQGLDWZTY": "Obtenga la experiencia de gestión completa",
    "JXTYDHD": "Continuar experimentando el lado del pedido",


//notice-detail

    "GongGaoXiangQing": "Detalles del anuncio",
    "GongGaoFuJian": "Adjunto de anuncio",


//shop-error

    "NFWDYMBCZO": "La página que visitaste no existe ~",
    "KeNengYuanYin": "Causa probable",
    "1_ZDZLSRCWD": "1. Ingrese la dirección incorrecta en la barra de direcciones;",
    "2_DJLGQLJ": "2. Hizo clic en el enlace desactualizado.",


//shop-index

    "GuanLiDuan": "Gestión",
    "ZhuCe": "Registrarse",
    "HYNTYYSDH": "Bienvenido a experimentar pedidos en la nube",
    "TYZHSJHDQG": "Los datos de la cuenta de experiencia se actualizan regularmente, no ingrese información importante",
    "TYZHZDNRSJ": "Los datos de contenido en la cuenta de experiencia son ficticios, no ingrese información importante",
    "ZHZCCG": "Registro de cuenta exitoso",
    "DengLuZhangHao": "Usuario",
    "CSMMW": "La contraseña inicial es",
    "GNFYHQL": "Enviarte un cupón",
    "Man1YuanKeYong": "Disponible por más de $ 1",
    "LQH1TNKY": "Disponible dentro de 1 día después del recibo",
    "YiJianLingQu": "Recogida con un clic",
    "GXNHD1ZYHQ": "Felicidades por obtener un cupón",
    "Gong1Yuan": "Total 1 yuan",
    "LiJiShiYong": "Usar ahora",


//shop-login

    "NHWDL": "No has iniciado sesión",


//shop-notice

    "ShangChengGongGao": "Anuncio de centro comercial",
    "ZanWuGongGao": "Sin anuncio",
    "1GeFuJian": "{num} archivo adjunto",


//deposit-detail

    "KouKuan": "Deducción",
    "ChongZhi_Kuan": "Recarga",
    "KouKuanZuoFei": "Deducción nulo",
    "ChongZhiZuoFei": "Recarga nula",
    "ZhangHuMingXi": "Detalles cuenta",
    "ZhangHuYuE": "Saldo cuenta",
    "LiJiChongZhi": "Recarga ahora",


//deposit-recharge

    "CZJEBNXY1Y": "La monto de recarga no puede ser inferior a 1 soles",
    "QSRZQDSZ": "Ingrese el valor correcto",
    "ZDYJE": "Cantidad personalizada",
    "ZWKTXSCZGN": "La función de recarga en línea aún no se ha activado",
    "ShiFuJinE": "Cantidad pagada",
    "ZhangHuChongZhi": "Recarga cuenta",
    "QXZCZJE": "Seleccione la monto recarga",
    "QSRCZJE": "Ingrese el monto recarga",
    "QLXNDZSYWY": "Póngase en contacto con su vendedor dedicado para recargar",
    "ShouJia": "Precio",
    "YiShouQing": "Agotado",
    "ChongZhiJinE": "Cantidad recarga",
    "QueRenFuKuan": "Confirmar pago",


//deposit-recharge-result

    "QSDDID": "ID pedido faltante",
    "ChongZhiChengGong": "Recarga exitosa",
    "ChongZhiShiBai": "Recarga fallida",
    "CLZ_QSH...": "Procesando, por favor espere ...",
    "ChongZhiFangShi": "Método recarga",
    "ChongZhiShiJian": "Tiempo recarga",
    "JYLSH": "Número de serie de transacción",
    "FanHuiShangCheng": "Vuelta al Mall",


//recharge-online

    "ZFSB_QCS": "Pago fallido, por favor intente nuevamente",
    "ZFYQX": "Pago cancelado",
    "ZFYC_QCS": "Pago anormal, por favor intente nuevamente",


//recharge-online

    "LingQuanZhongXin": "Centro de cupones",
    "WeiShiYong": "No utilizado",
    "YiShiYong": "Usado",
    "ZWYHQ": "Sin cupones",


//keep-list

    "YiGouMai": "Comprado",
    "ChangYongQingDan": "Lista común",
    "WoDeShouCang": "Favoritos",
    "QuGouWuChe": "Ir al carro",
    "GeShangPin": "Productos",


//login

    "YunShangDingHuo": "Holaplaza.com",
    "QSRZH": "Ingrese su cuenta!",
    "QSRMM": "Ingrese su contraseña!",
    "QSRDLZH": "Ingrese una cuenta de inicio sesión",
    "JiZhuMiMa": "Recordar contraseña",
    "WangJiMiMa": "¿Olvidaste tu contraseña?",
    "DengLu": "Iniciar sesión",
    "DLZHBCZ_QL": "La cuenta de inicio de sesión no existe, póngase en contacto con el administrador para activar",
    "GQYZHSBJY_": "La versión de prueba de la cuenta empresarial está deshabilitada, póngase en contacto Holaplaza.com",
    "GQYZHSYQJS_": "El período de prueba de la cuenta empresarial ha terminado, comuníquese Holaplaza.com",
    "GQYZHYGQL_": "Esta cuenta empresarial ha caducado y requiere renovación para usarla, comuníquese con Holaplaza.com.",
    "LiJiTiYan": "Pruébalo ahora",


//my-collect

    "YiChuChengGong": "Eliminado con éxito",
    "QueHuo": "Sin Stock",
    "YCSCJ": "Eliminar favoritos",


//order-message

    "SheZhiChengGong": "Configurar éxito",
    "ZWWDXX": "No hay mensajes no leídos",
    "QuanBuYiDu": "Leer todo",
    "DingDanXiaoXi": "Mensaje de pedido",


//

    "YiQiang": "Agarrado",
    "LiJiLingQu": "Reclama ahora",
    "LingQuanHou": "Después de recibir el cupón",
    "TianNeiKeYong": "Disponible en dias",


//

    "QSRSJH": "Ingrese su número de móvil",
    "QSRYZM": "Ingrese el código verificación",
    "QSRXMM": "Ingrese una nueva contraseña",
    "QSRZQSJGS": "Ingrese el formato de móvil correcto",
    "ChongZhiMiMa": "Restablecer contraseña",
    "QSRNDSJHM": "Por favor ingrese su número de celular",
    "QSRDXYZ": "Por favor ingrese la verificación por SMS",
    "HQYZM": "Obtén el código de verificación",
    "HKCXHQ": "Se puede recuperar más tarde",
    "QSR6_20WSZHZ": "Ingrese una contraseña de 6-20 dígitos o letra",


//share-setting

    "QSRSPJGBL": "Ingrese la relación precio del producto",
    "SPJGBLBXDY": "La relación del precio debe ser mayor o igual que 100 y menor que 999.99",
    "DPMCZBZCTS": "El nombre de la tienda no admite símbolos especiales",
    "LXRZBZCTSF": "Los contactos no admiten símbolos especiales",
    "LXFSZBZCTS": "El método de contacto no admite símbolos especiales",
    "LXDZZBZCTS": "La dirección de contacto no admite símbolos especiales",
    "DPJJZBZCTS": "El perfil de la tienda no admite símbolos especiales.",
    "QSRDPJJ_ZD": "Ingrese una descripción de la tienda, hasta 50 caracteres.",
    "FenXiangSheZhi": "Configuración de uso compartido",
    "NDWSCSPJG": "Su precio de producto WeChat",
    "NDDHJ_": "El precio de pedido ×",
    "LXXX_KBJ_": "Información de contacto (editable)",
    "DianPuMingCheng": "Nombre tienda",
    "JianJie": "Introduccion",
    "QSRDPMC": "Ingrese el nombre tienda",
    "LianXiRen": "Persona contacto",
    "QSRLXR": "Ingrese un contacto",
    "LianXiFangShi": "Móvil de contacto",
    "QSRLXFS": "Ingrese la información contacto",
    "LianXiDiZhi": "Direccion contacto",
    "QSRLXDZ": "Ingrese una dirección contacto",


//user

    "WoDeDingDan": "Mi pedido",
    "ZuiJinLiuLan": "Visto recientemente",
    "ShouCangShangPin": " Favoritos",
    "BDYYZH_ZC": "Vincula una cuenta existente | Registra una cuenta nueva",
    "DL_ZC": "Iniciar sesión | Registrarse",
    "BangDingShouJi": "Vincular Móvil",
    "TS_BDSJHM_": "Consejo: vincule su número de teléfono móvil para que su cuenta sea más segura",
    "ChaKanQuanBu": "Ver todo",
    "ShouHuoDiZhi": "Dirección envío",
    "LianXiWoMen": "Contáctanos",
    "SheZhi": "Configurar",
    "QuQianDao": "Ir a registrarse",
    "JiFenShangCheng": "Mall puntos",
    "TuiGuangShangPin": "Promocionar",
    "QuChaKan": "Ir a comprobar",
    "JiFenHuanLi": "Intercambio de puntos",
    "DYSPBQ": "Imprimir etiquetas de productos",


//user-about

    "GYYSDH": "Holaplaza.com",
    "YQHYSMEWM_": "Invite a un amigo a escanear el código QR, descargue e instale directamente",
    "DangQianBanBen": "Versión actual",


//user-address

    "ZWSHDZ": "Sin dirección envío",
    "WoDeDiZhi": "Mi direccion",
    "ZhiNengShiBie": "Reconocimiento inteligente",
    "ZTDZXXKZNS": "Pegar la información de la dirección puede identificar de manera inteligente el nombre, el teléfono y la dirección",
    "MoRen": "Predeterminado",

//

    "QRSCDZ": "Confirmar eliminar dirección?",
    "SHRXM": "Consignatario",
    "SJHMHGDDHZ": "Rellene al menos un número de teléfono móvil o fijo",
    "QSRSHRXM": "Ingrese el nombre del consignatario",
    "ShouJiHaoMa": "móvil",
    "GuDingDianHua": "Teléfono fijo",
    "QSRGDDH": "Ingrese un teléfono fijo",
    "SuoZaiDiQu": "Zona",
    "QSRSZDQ": "Por favor ingrese su área",
    "QSRXXDZ": "Ingrese la dirección detallada!",
    "QSRZQDSJHM": "Ingrese el número móvil correcto",
    "QSRZQDGDDH": "Por favor, introduzca el teléfono fijo correcto",
    "XiangXiDiZhi": "Dirección",
    "MoRenDiZhi": "Predeterminado",
    "SWMRDZ": "Predeterminado",
    "BCBSY": "Guardar y usar",


//user-bing-one

    "QSRSJHM": "Ingrese su número móvil",
    "GYHYZC": "El usuario ya está registrado.",
    "GSJHMYJZCG": "El número de teléfono ya está registrado.",
    "QZJDLJK": "Inicia sesión directamente",
    "QuDengLu": "Ir al inicio sesión",
    "BDHKYTGSJH": "Después de la vinculación, puede iniciar sesión a través de su número de teléfono móvil, lo que le facilita recuperar su contraseña y mejorar la seguridad de la cuenta.",
    "_BDHKYTGSJ": "* Después del enlace, puede iniciar sesión a través de su número de teléfono móvil, para que pueda recuperar su contraseña y mejorar la seguridad de la cuenta.",
    "QSRSJYZM": "Ingrese el código verificación móvil",
    "BDXSJ": "Enlazar teléfono nuevo",
    "DXYZM": "Código verificación de SMS",
    "XiaYiBu": "Paso siguiente",
    "TGCBZ": "Omite este paso",


//user-bing-two

    "DBDSJHMBCZ": "¡El número móvil no existe!",
    "QSRDLMM": "Contraseña (6-20 letras o números)!",
    "_GSJHMYBDY": "* El número de teléfono móvil ya está vinculado a una cuenta de pedido en la nube. Verifique la contraseña de inicio de sesión del teléfono móvil y puede vincular la cuenta actual",
    "DQZHMMJTBW": "La contraseña de la cuenta actual se sincronizará con la contraseña de inicio de sesión del teléfono.",
    "DangQianZhangHao": "Cuenta corriente",


//

    "QDYJZ": "Seguro de hacer esto",
    "JSPSCM": "Artículos eliminados?",
    "ShanChuChengGong": "Borrado éxito!",


//user-info

    "SJGSCW": "Error de formato del móvil",
    "YXGSCW": "El correo electrónico está mal formado",
    "WoDeZhangHao": "Mi cuenta",
    "YouXiang": "Email",
    "ShouJi": "Móvil",
    "XingMing": "Nombre",
    "XiuGaiTouXiang": "Modificar avatar",
    "JiBenXinXi": "Información básica",
    "ZhangHaoAnQuan": "Seguridad de cuenta",
    "XiuGaiMiMa": "Cambiar contraseña",
    "DianJiBangDing": "Click Bind",
    "RYDSHTY_QL": "Si se pierde o se desactiva, reemplácelo de inmediato para evitar el robo de cuenta",
    "WBZNDZHAQ_": "Vincular con su celular",
    "QSRNDXM": "Ingrese su nombre",
    "QSRNDDH": "Ingrese su móvil",
    "QSRNDYX": "Ingrese su Email",


//user-setting

    "QCHCHXCXDL": "Inicie sesión nuevamente después de borrar el caché",
    "QingChuHuanCun": "Borrar caché",
    "QDYQCHCM": "¿Seguro que quieres borrar el caché?",
    "TuiChuDengLu": "Cerrar sesión",
    "QieHuanZhangHao": "Cambiar cuenta",


//user-supplier

    "LianXi": "Contacto",
    "GongSiDianHua": "Teléfono",
    "DiZhi": "Dirección",


//vouchers-detail

    "DJQMX": "Detalles Cupón",


//vouchers-list

    "BuKeYong": "No disponible",
    "KeYong": "Disponible",
    "ZWDJQ": "Sin cupones",
    "ZongEDu": "Monto total",
    "FaFangShiJian": "Tiempo de liberación",
    "EDYYW": "La cuota se ha agotado",
    "QBSPKY": "Todos los productos disponibles",


//vouchers-select-list

    "WDDJQ": "Mi Cupón",
    "KYDJQ": "Cupón disponibles",
    "DJQKDK": "Los cupones son deducibles",
    "CKKYSP": "Productos disponibles",
    "BKYDJQ": "Cupones no disponibles",
    "BKYYY_ZWFH": "Motivo no disponible: no hay productos elegibles",

    //goods-detail

    "DQSPWSCGGT": "¡El producto actual no ha cargado el mapa de especificaciones y no es compatible con la lámpara!",
    "BoFangShiPin": "Play",
    "Gong1GeGuiGe": "Total {num} Especs",
    "YiXuanGuiGe": "Especificaciones seleccionadas",
    "XuanGuiGe": "Elige especificaciones",
    "LingQuan": "Cupón",
    // "Jian":"Menos",
    "1ZhongCuXiao": "1 promoción",
    "1ZhangKeYong": "1 disponible",
    "ZuiGaoKeSheng": "Hasta para ahorrar",
    "ShangPinTaoCan": "Paquete de productos básicos",
    "Gong1GeTaoCan": "1 paquete",
    "ShangPinCanShu": "Parámetros del producto",
    "ZhanKai": "Expandir",
    "YiShouCang": "Favorito",
    "ShouCang": "Favorito",
    "KeFu": "Servicio al cliente",
    "XiangQing": "Detalles",
    "TuiJian": "Popular",
    "BianHao": "Numero",
    "ShangPinXinXi": "Información del producto",
    "KLYHQ": "Obtener cupón",
    "KuCunBuZu": "Fuera de stock",
    "DuoGuiGe": "Especificaciones múltiples",
    "YiShou": "Vendido",
    "SWBHZ_YS": "Agotado al reponer existencias",
    "CuXiaoShangPin": "Artículos promocionales",


//goods-search

    "NHWSRSSNR": "No has ingresado tu búsqueda",
    "SouSuo": "Buscar",
    "SSY1XGDSP": "Buscar {searchKey} productos relacionados",
    "Yue1GeShangPin": "Acerca de {num} producto",
    "ZuiJinSouSuo": "Buscado recientemente",
    "QingChu": "Claro",
    "ZWSSJL": "Sin historial de búsqueda",


//goods-property

    "ZGJGBNDYZD": "El precio más alto no puede ser más bajo que el precio más bajo.",
    "ShangPinBiaoQian": "Etiqueta del producto",
    "BuGaoYu1": "No más de 1",
    "BuDiYu1": "No inferior a 1",
    "FengGe": "Estilo",


//goods-promotion-list

    "JiJiangKaiShi": "A punto de comenzar",
    "QuanBuCuXiao": "Todas las promociones",


//goods-package-list

    "Gong1JianShangPin": "1 productos",
    "Sheng": "Provincia",


//goods-package-detail

    "GTCZGWCZDS": "La cantidad de este paquete en el carrito de compras ha alcanzado el límite de compra y no se puede agregar al carrito de compras.",
    "GTCKCBZ_BK": "Este paquete está agotado y no se puede agregar al carrito",
    "JinSheng1Tao": "Solo queda 1 juego",
    "XianGou1Tao": "Limitado a 1 juego",
    "TaoCanXiangQing": "Detalles del paquete",
    "DanMaiJia": "Precio de compra individual",
    "TaoCanJia": "Precio del paquete",
    "HDYJS": "El evento ha terminado.",


//goods-message

    "WDL_QXDL": "No ha iniciado sesión, inicie sesión primero",


//goods-list-label

    "XinPinShangShi": "Nuevo listado",
    "WDLZH_ZWCX": "¡Sin cuenta de inicio de sesión, sin promociones!",
    "YiXiaShangPin": "Los siguientes productos",


//goods-list

    "1ZhongGuiGe": "1 especificación",
    "YouKuCun": "En stock",


//goods-filter
    "BuGaoYu": "No más",
    "BuDiYu": "No inferior",
    "CuXiao": "Promoción",
    "ZDYXXZ3G": "Permite la selección de hasta 3",
    "GouMai": "Comprar",

    "Gong1Jian": "Total {num}",
//
    "DLHCKJG": "Consultar precios después de iniciar sesión",
    "M1JZ1J": "Compra {presentNum} Unds, llevas {num} gratis",
    "CZPF_ZZCXC": "Operación frecuente, reintento",
    "XSZJJXZ": "Descenso continuo en tiempo limitado",
    "YXSPM1YZ1J_": "Para los siguientes artículos {str}. ¡Agregue un carrito de compras para disfrutar del descuento!",
    "TiaoXingMa": "Código barras",
    "XiangGuanTuiJian": "Recomendaciones relacionadas",
    "YSSPBKJRGW": "¡Los artículos de preventa no se pueden agregar al carrito de compras, se han agregado artículos que no están en venta!",
    "YSSPBKLJGM": "Los artículos de preventa no se pueden comprar de inmediato, ¡elimine los artículos de preventa y vuelva a comprarlos!",
    "SPSLYW": "Cantidad incorrecta de productos",
    "ShangPin1QiShou": "Artículos de {num}",
    "SPDGSLBNCG": "La cantidad del pedido no puede exceder {num}",
    "QRYCXZSP": "Confirmar la eliminación de los elementos seleccionados.",
    "QRYCSYSXSP": "¿Confirme la eliminación de productos apagados?",
    "QRYCXZSXSP": "Confirme la eliminación de los elementos caducados seleccionados",
    "M1YZ1J": "{presentNum} Regalo por S/ {num} de compra",
    "M1YJ1Y": "S/ {price} menos S/ {money}",
    "M1YD1Z": "{money}% de descuento para pedidos superiores a S/ {price}",
    "DDM1YHZ": "Reciba una compra de S/ {price} o más",
    "YiHuoZeng": "Dotados",
    "ZG1YLXM1YZ1": "Falta S/ {price} y disfrute de {str}",
    "ZG1JLXM1JZ1": "Compre {num} más {str}",
    "QCSXSP": "Productos apagados",
    "YiXuanShangPin": "Productos seleccionados",
    "QuJieSuan": "Confirmar pedido",
    "ShiXiaoShangPin": "Bienes muertos",
    "DingDanGuanLi": "Gestión pedidos",
    "ZhuangTai": "Estado",
    "ShangPinChuKu": "enviado",
    "DengDaiShouHuo": "recibido",
    "PeiSongXinXi": "Información de entrega",
    "FuKuanXinXi": "Información de pago",
    "GDFKJL": "Más historial de pagos",
    "WuLiuFuJian": "Accesorios de logística",
    "ZanWuFuJian": "Sin archivos adjuntos",
    "YiFa": "Enviado",
    "YingFuZongE": "Total a pagar",
    "FuKuanShiJian": "Tiempo de pago",
    "ZhangHuHaoMa": "Número de cuenta",
    "KeYongYuE": "Saldo disponible",
    "TianJiaChengGong": "Agregado exitosamente",
    "ShanChuDiZhi": "Eliminar dirección",
    "QXZSHDZ": "Por favor seleccione una dirección de entrega",
    "YiHuoZengPin": "Otorgado",
    "YiYouHui1Yuan": "S/ {money} de descuento",
    "QWSFPXX": "Por favor complete la información de la factura",
    "1ZSP": "productos",
    "Gong": "Total",
    "Jian": "Und",
    "ShangPinJinE": "Sub-Total",
    "YunFeiDaoFu": "Flete por cobrar",
    "QSRZQDSHRX": "Por favor ingrese el nombre correcto del destinatario",
    "QSRZQDXXDZ": "Por favor ingrese la dirección correcta",
    "DBQ_MYZDDY": "Lo sentimos, no se encontró la página correspondiente",
    "ShouHuoChengGong": "Entrada de mercancías",
    "Hi_HuanYingNin": "Hola bienvenido",
    "YiFaEWuLiu": "Enviado e Logística",
    "NinDeDingDan": "Su orden",
    "ZBNBHBQ": "Emoji no puede ser incluido",
    "XMBNWK": "El nombre no puede estar vacío",
    "XiTongSheZhi": "Modificar Info",
    "ZhangHaoXinXi": "Info cuenta",
    "QingShuRuXingMing": "Por favor ingrese un nombre",
    "QWSZL_JZYG": "Complete la información, lo ayudará a servirle mejor",
    "QSRZQDYXGS": "Por favor ingrese el formato de correo electrónico correcto",
    "QTXZQDCWXX": "Complete la información financiera correcta para que su socio pueda facturarle",
    "GXSHDZCG": "Actualizar la dirección de envío correctamente",
    "BCSHDZCG": "Guardar la dirección de envío con éxito",
    "XinZengDiZhi": "Agregar",
    "QXZYQXSCDS": "Por favor, seleccione el elemento que desea recoger",
    "QuXiaoShouCang": "Cancelar colección",
    "XuanZeGuiGe": "Seleccionar especificaciones",
    "CaiWuGuanLi": "Gestión financiera",
    "DuiZhangMingXi": "Pago",
    "QSRLWJL_JE": "Número de pedido",
    "YiGouMai1Ci": "Comprado {count} vez",
    "6_1GouMai": "{time} compra",
    "TZGGXQ": "Detalles del anuncio de notificación",
    "ZYXSCSP": "Solo sube videos",
    "SCSPDXBYXC": "El tamaño de carga de video no puede exceder los {size}M",

//
    "YDHSP": "Comprador",
    "ShangPinPinPai": "Marca",
    "ShangPinLieBiao": "Lista productos",
    "LingShouJia": "Precio normal",
    "YouHuiJia": "Precio oferta",
    "LiJiGouMai": "Comprar ahora",
    "ZhuTu": "Foto",
    "WuXianZhi": "Ilimitado",
    "XiaoJi": "Subtotal",
    "SCXZSP": "Eliminar seleccionados",
    "QSRDDH": "Pedido",
    "QSRDDH_SPMC": "Pedido / Producto",
    "CaoZuo": "Operación",
    "WuLiuXinXi": "Información de Envío",
    "CaoZuoLeiXing": "Tipo operación",
    "CaoZuoRiZhi": "Registro de operación",
    "CaoZuoShiJian": "Tiempo de operación",
    "ZhangHuMingCheng": "Nombre de cuenta",
    "KaiHuYinHang": "Cuenta bancaria",
    "DDTJCG_XZZC": "Pedido enviado correctamente, ahora solo queda un paso",
    "ZhiFuJinE": "Monto de pago",
    "DZFJE": "Por pagar",
    "QTXYXSMB": "Completa algunas instrucciones",
    "XZSKYHZH": "Seleccionar cuenta bancaria beneficiaria",
    "TiShi": "Pista",
    "NHY1GDDGJ1Y": "Tienes {num} pedido restante por S/ {money} pendiente",
    "QKJQHCKJXX": "Los pedidos no se pueden realizar hasta que se liquiden los atrasos",
    "HDDDXX": "Verifique la información del pedido",
    "BJSHDZ": "Editar dirección de envío",
    "TongZhiGongGao": "Notificaciones",
    "YinHangZhangHao": "Cuenta bancaria",
    "XiuGaiShouJi": "Modificar teléfono",
    "LXRXX": "Información de contacto",
    "GSCWXX": "Información financiera de la empresa",
    "QSRKHYHMCJ": "Por favor ingrese el nombre del banco y la sucursal",
    "QSRYHZH": "Por favor ingrese su número de cuenta bancaria",
    "PiLiangCaoZuo": "Operación por lotes",
    "GouMaiJiLu": "Mis compras",
    "TongZhiBiaoTi": "Título de la notificación",
    "FaBuShiJian": "Tiempo de liberación",
    "XiaoXiLeiXing": "Tipo de mensaje",
    "XiaoXiNeiRong": "Contenido del mensaje",
    "JieShouShiJian": "Tiempo de aceptación",
    "QuanBuPinPai": "Todas las marcas",
    "WXSYS_DHGBJ": "Escanee en WeChat para ordenar más fácilmente",
    "GZWXGZH": "Presta atención a la cuenta pública de WeChat",
    "FuWuReXian": "Atención",
    "SouSuoLiShi": "Historial de búsqueda",
    "QuanBuShanChu": "Eliminar todo",
    "NH_HYLDYSDH": "Bienvenidos a {company}!",
    "ZSJDH": "Holaplaza APP",
    "SYS_GZWXGZH": "Escanee, preste atención a la cuenta pública de WeChat",
    "GuanZhuWoMen": "Síguenos",
    "QBSPFL": "Categorías",
    "QuPeiDeng": "Ir con luces",
    "KuanXiang": "Pago",
    "QSRSPMC_GG_": "Ingrese el producto / especificación / número / primera letra",
    "NH_HYLDYSD": "Bienvenidos al Holaplaza.com",
    "ReXiao": "Oferta",
    "20Tiao_Ye": "20/Pagina",
    "ShiChangJia_": "Precio normal:",
    "￥": "S/ ",
    "_134.10Qi": "Desde S/ 134.10",
    "HJ_BHYF": "Monto total(Sin Flete):",
    "QDSCXZSP_": "¿Eliminar el productos seleccionado?",
    "QDQCXJSP_": "¿Eliminar el productos agotados?",
    "DDTJCG_XZZ": "Envío de pedidos con éxito, ahora solo queda un paso!",
    "XuanZeZhangHu": "Cuentas Bancarias",
    "DaiZhiFu": "Por pagar",
    "CaiGouDingDan": "Pedido",
    "DingDanXinXi": "Info Pedido",
    "TuiChu": "Salir",
    "KaiHuMingCheng": "Nombre cuenta",
    "QSRKHMC": "Por favor ingrese el nombre de la cuenta",
    "_QTXZQDCWX": "(Por favor complete la información financiera correcta)",
    "ShouHuoRen": "Consignatario",
    "SWMRSHDZ": "Elegir por defecto",
    "TianJiaDiZhi": "Agregar",
    "Gong4GeGuiGe": "Total 4 especificaciones",
    "Zhi": "Und",
    "Kuai": "Und",
    "Tou": "Und",
    "Xiang": "Und",
    "Ping": "Und",
    "KaiShiRiQi": "Fecha de inicio",
    "JieShuRiQi": "Fecha de finalización",
    "YiXuan": "Seleccionado",
    "ZhiFuZhuangTai": "Estado pago",
    "QDLCKJG": "Inicie sesión para ver el precio",
    "YZMBZQ": "Código de verificación es incorrecto",

    "ZhiFuJiLu": "Registro de pago",
    "ShangPinMingCheng": "Nombre producto",
    "DingGouShuLiang": "Ordene la cantidad",
    "TuiHuoShuLiang": "Cantidad devuelta",
    "QSRTHNR": "Por favor ingrese el contenido devuelto",
    "SCTHSM": "Subir instrucciones de devolución",
    "ShenQingShiJian": "tiempo de aplicación",
    "ShenQingJinE": "Cantidad de solicitud",
    "TuiHuoYuanYin": "Motivo de la devolución",
    "TuiHuoShuoMing": "Instrucciones de devolución",
    "TuiDanHao": 'Número de orden de devolución',

    "SXCZ_XDCG": "Credit enough, successful order",
    "SXEDBZ_FKHFH": "Insufficient credit line, delivery after payment",
    "CGJKZQ_FKFH": "After the borrowing period, payment delivery",
    "DDTJCG_XZZCZHYB": "The order was submitted successfully. Now we are only one step away",
    "QNZ_NWCFK_FZ": "Please complete the payment within {time}, otherwise the order will be cancelled automatically.",
    "MianFeiLing": "免费领",
    "M_KMFLQ_HQ": "满{target}元可免费领取，还差{short}元",
    "YHDMFSP": "已获得免费商品",
    "JRMFL": "今日免费领",
    "LingGuangLe": "领光了",
    "KeLingQu": "可领取",

    "QSRZFJE": "Please enter the payment amount",
    "QNJKFK_WMJKWNCL": "Please pay as soon as possible, we will process the order for you as soon as possible!",

    "XianXiaTiYanGuan": 'Offline experience Pavilion',
    "QingXuanZheSheng": 'Please select a province',
    "QingXuanZheShi": 'Please select the city',
    "ZhanGuanDiZhi": 'The pavilion address',
    "YingYeShiJian": 'Business hours',
    "ZhanGuanDianHua": 'The pavilion phone',
    "FenXiang": "Share",
    "TiYanGuanJieShao": "Sharing experience Pavilion introduction",


    "DPYDY_QSHX": "La tienda está cerrada, haga un pedido más tarde.",
    "DaYangShiJian": "Hora de cierre",
    'IMEI': 'IMEI',

    "FanKuanZhangHu":"Cuenta de reembolso",
    "FanKuanYuE":"Saldo de reembolso",
    "FanKuanZhiFu":"Pago de reembolso",
    "FKYEBZ":"Saldo de reembolso insuficiente",
    "HaiXuZhiFu":"Necesito pagar {price}",

    "FaPiaoMingXi":"Datos de la factura",
    "FaPiaoZhuShou":"Asistente de facturas",
    "WoDeFaPiao":"Mi factura",
    "FaPiaoXiangQing":"Detalles de la factura",
    "KaiPiaoShiJian":"Tiempo de facturación",
    "DaiKaiFaPiao": "Por facturar",
    "DKFPJE":"Importe a facturar",
    "KaiPiaoJinE":"Monto facturación",
    "GuanLianDanHao": "Número de orden de asociación",
    "ChuangJianShiJian": "Hora de creación",
    "ZhiFuPingZheng": "Comprobante de pago",
    "QSRZQNR": "Por favor, introduzca el contenido correcto",
    "QSRTDH_SPMC": "Por favor, introduzca el número de devolución/nombre de la mercancía",
    "ShangPinRuKu": "Almacenamiento de mercancías",
    "TuiKuanChuLi": "Tratamiento de las restituciones",
    "YuanDingDanHao": "Número de orden original",
    "TuiHuoXinXi": "Información sobre las devoluciones",
    "TuiKuanXinXi": "Información sobre la devolución",
    "TuiKuanZhuangTai": "Estado de la restitución",
    "ZWTKJL": "No hay registro de devoluciones",
    "GDTKJL": "Más registros de reembolsos",
    "DDDKFQRBJQSH": "Por favor confirme la oferta más tarde",
    "BNSRDYHDTSZF": "No se pueden introducir caracteres especiales como comillas simples",
    "THSLBNWK": "El número de devoluciones no puede ser vacío",
    "BZBNBHBQ": "Las observaciones no deben contener expresiones",
    "THSLBKDYKTHSL": "El número de devoluciones es mayor que el número de devoluciones disponibles",

    "DDM_YZ_J":"UN pedido completo {target} $para {number} piezas",
    "M_JZ_J":"Full {target} pieces for {number} pieces",
    "M_JJZ_Y":"La pieza completa {target} desciende a {money} yuan",
    "YDHJJZ_Y":"El precio del pedido original se redujo a {prICE} $",
    "M_JD_Z":"Full {number} piezas con UN descuento de {percent}0%",
    "YDHJD_Z":"Descuento sobre el precio del pedido original {percent}0%",

    // Mercado Pago支付
    "WDSYT": "Mi caja",
    "ZZSYMPSMJY": "Realiza pago usando código QR de MercadoPago",
    "SKF": "Beneficiario",
    "SYSFK": "Escanear y pagar",
    "DDXQ": "Detalles del pedido",
    "YUAN": 'S/'
};
export default en_US;
