import React, {Fragment} from 'react';
import classnames from 'classnames'
import './style.scss'
import {injectIntl} from 'react-intl'
import {LocalConst} from "../../utils/constant";
import StorageUtil from "../../utils/storage-util";
import {SystemFetch} from "../../services/service";
import CommonUtil from "../../utils/common-util";

@injectIntl
class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.userInfo = StorageUtil.getItem(LocalConst.userInfo);
        this.state = {
            userInfo: {}
        };
    }

    componentDidMount() {
        if (CommonUtil.isLogin()) {
            this.getUserInfo();
        }
    }

    toPage = (item) => {
        const {history} = this.props;
        history.push(item.router)
    };

    getUserInfo = () => {
        SystemFetch.getUserInfo(null, rs => {
            if (rs.status) {
                this.setState({
                    userInfo: rs.data
                })
            }
        })
    };

    render() {
        const {pathname, intl} = this.props;
        const {userInfo} = this.state;
        this.intl = intl;
        const menuList = [
            {
                name: (this.intl.messages['WoDe'] || '我的'),
                children: [
                    {
                        name: (this.intl.messages['GeRenZhongXin'] || '个人中心'),
                        router: '/user'
                    },
                    {
                        name: (this.intl.messages['TongZhiGongGao'] || '通知公告'),
                        router: '/notices/list'
                    },
                    {
                        name: (this.intl.messages['DingDanXinXi'] || '订单信息'),
                        router: '/order/message'
                    },
                    {
                        name: (this.intl.messages['ShouCangShangPin'] || '收藏商品'),
                        router: '/collect/list'
                    },
                    {
                        name: (this.intl.messages['GouMaiJiLu'] || '购买记录'),
                        router: '/keep/list'
                    },
                    {
                        name: (this.intl.messages['YouHuiQuan'] || '优惠券'),
                        router: '/coupon/list'
                    },
                    {
                        name: '子账号',
                        router: '/subAccount',
                        isHidden: !!userInfo.is_child_
                    }
                ]
            },
            {
                name: (this.intl.messages['DingDanGuanLi'] || '订单管理'),
                children: [
                    {
                        name: (this.intl.messages['DingHuoDan'] || '订货单'),
                        router: '/order/list'
                    },
                    {
                        name: (this.intl.messages['TuiHuoDan'] || '退货单'),
                        router: '/refund/list'
                    },
                    {
                        name: '发货记录',
                        router: '/order/outHouseLog/list'
                    },
                    {
                        name: '订单统计',
                        router: '/order/statistics'
                    },
                ]
            },
            {
                name: (this.intl.messages['CaiWuGuanLi'] || '财务管理'),
                children: [
                    {
                        name: (this.intl.messages['DuiZhangMingXi'] || '对账明细'),
                        router: '/deposit/detail'
                    },
                    {
                        name: (this.intl.messages['FaPiaoMingXi'] || '发票明细'),
                        router: '/invoice/list'
                    },
                ]
            },
            {
                name: (this.intl.messages['XiTongSheZhi'] || '系统设置'),
                children: [{
                    name: (this.intl.messages['ZhangHaoXinXi'] || '帐号信息'),
                    router: '/account'
                }, {
                    name: (this.intl.messages['ShouHuoDiZhi'] || '收货地址'),
                    router: '/address'
                },]
            },
        ];

        return (
            <section className="user-menu-box">
                <dl>
                    {
                        menuList.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <dt>{item.name}</dt>
                                    {
                                        item.children.map((subItem, sIndex) => {
                                            if (subItem.isHidden) {
                                                return;
                                            }
                                            const cls = classnames({'active': subItem.router === pathname});
                                            return (
                                                <dd className={cls} role="none" key={sIndex}
                                                    onClick={() => this.toPage(subItem)}>{subItem.name}</dd>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </dl>
            </section>
        )
    }
}

export default UserMenu
